import { selectUser } from './redux/appSlice';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useAuthContext } from './context/AuthContext';
import { useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import NewCreationDashboard from './pages/NewCreationDashboard/NewCreationDashboard';
import UpgradeSuccess from './pages/UpgradeSuccess/UpgradeSuccess';
import TermsOfService from './pages/TermsOfService/TermsOfService';
import UpgradePlanNew from './pages/UpgradePlanNew/UpgradePlanNew';
import UserDashboard from './pages/UserDashboard/UserDashboard';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import PrivicyPolicy from './pages/PrivicyPolicy/PrivicyPolicy';
import OverviewCode from './pages/OverviewCode/OverviewCode';
import UpgradeError from './pages/UpgradeError/UpgradeError';
import CheckoutForm from './pages/CheckoutForm/CheckoutForm';
import UpgradePlan from './pages/UpgradePlan/UpgradePlan';
import CodeExpired from './pages/CodeExpired/CodeExpired';
import ContactUs from './pages/ContactUs/ContactUs';
import Analytics from './pages/Analytics/Analytics';
import CodePage from './pages/CodePage/CodePage';
import EditCode from './pages/EditCode/EditCode';
import Settings from './pages/Settings/Settings';
import Redirect from './pages/Redirect/Redirect';
import Header from './components/Header/Header';
import NewHome from './pages/NewHome/NewHome';
import SignUp from './pages/SignUp/SignUp';
import Charge from './pages/Charge/Charge';
import SignIn from './pages/SignIn/SignIn';

import signUpFrameBackgroundSvg from './assets/icons/sign-up-frame-background.svg';

import 'react-date-range/dist/theme/default.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css';
import './styles/styles.css'
import './styles/pages.css'

function App() {
  const [showHeader, setShowHeader] = useState(true)
  const user = useSelector(selectUser)

  const { googleWithDownlaod } = useAuthContext()

  const location = useLocation()

  useEffect(() => {
    const newImage = new Image();
    newImage.src = signUpFrameBackgroundSvg;
    window[signUpFrameBackgroundSvg] = newImage;
  }, [])

  useEffect(() => {
    if (location.pathname.includes('/code') || location.pathname.includes('/code-expired')) {
      setShowHeader(false)
    } else {
      setShowHeader(true)
    }
  }, [location])

  const userRoutes = () => {
    return (
      <>
        <Route path='dashboard' element={<UserDashboard />} />
        <Route path='dashboard/edit/:id' element={<EditCode />} />
        <Route path='dashboard/analytics' element={<Analytics />} />
        <Route path='upgrade-plan' element={<UpgradePlan />} />
        <Route path='upgrade-plan-new' element={<UpgradePlanNew />} />
        <Route path='upgrade-plan/checkout/:secret/:plan' element={<CheckoutForm />} />
        <Route path='upgrade-plan/success' element={<UpgradeSuccess />} />
        <Route path='upgrade-plan/error' element={<UpgradeError />} />
        <Route path='contact-us' element={<ContactUs />} />
        <Route path='terms-of-service' element={<TermsOfService />} />
        <Route path='privacy-policy' element={<PrivicyPolicy />} />
        <Route path="code/:id" element={<CodePage />} />
        <Route path="code-expired" element={<CodeExpired />} />
        <Route path="settings" element={<Settings />} />
        <Route path="charge" element={<Charge />} />
        <Route path="overview-code/:id" element={<OverviewCode />} />
        <Route path="redirect" element={<Redirect />} />
        <Route path="*" element={<Navigate to="dashboard" replace />} />
      </>
    )
  }

  const anonymousRoutes = () => {
    return (
      <>
        <Route path='' element={<NewHome />} />
        <Route path='generator' element={<NewHome />} />
        <Route path='generate-code' element={<NewCreationDashboard />} />
        <Route path="code/:id" element={<CodePage />} />
        <Route path='reset-password' element={<ResetPassword />} />
        <Route path='contact-us' element={<ContactUs />} />
        <Route path='terms-of-service' element={<TermsOfService />} />
        <Route path='privacy-policy' element={<PrivicyPolicy />} />
        <Route path="code-expired" element={<CodeExpired />} />
        <Route path="charge" element={<Charge />} />
        <Route path="sign-up" element={<SignUp signUpFrameBackgroundSvg={signUpFrameBackgroundSvg} />} />
        <Route path="register" element={<SignUp signUpFrameBackgroundSvg={signUpFrameBackgroundSvg} />} />
        <Route path="sign-in" element={<SignIn signUpFrameBackgroundSvg={signUpFrameBackgroundSvg} />} />
        <Route path="redirect" element={<Redirect />} />
        <Route path="*" element={<NewHome />} />
      </>
    )
  }

  return (
    <div className="flex flex-col items-center min-h-screen d-flex-important">
      {showHeader && (
        <Header />
      )}
      {user && !googleWithDownlaod ? (
        <div className='dashboard-content-wrapper'>
          <Routes>
            {userRoutes()}
            <Route path=":lang/*">
              {userRoutes()}
            </Route>
          </Routes>
        </div>
      ) : (
        <div className='main-content-wrapper'>
          <Routes>
            {anonymousRoutes()}
            <Route path=":lang/*">
              {anonymousRoutes()}
            </Route>
          </Routes>
        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
      />
    </div>
  );
}

export default App;
