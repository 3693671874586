import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import { useCustomNavigate } from "../../hooks/useCustomNavigate";

const REDIRECT_KEY_TO_PATH = {
    "upgrade_plan_consider_upgrading": "upgrade-plan-new?from_email=qr_code_consider_upgrading_email",
    "dashboard": "dashboard",
    "upgrade_plan_trial_ended": "upgrade-plan-new?from_email=free_trial_period_ended_email",
    "settings_tabs_plan": "settings?tab=plans",
    "upgrade_plan_upgrade_awaits": "upgrade-plan-new?from_email=upgrade_awaits_email",
    "upgrade_plan": "upgrade-plan-new",
    "dashboard_analytics": "dashboard/analytics"
}

const Redirect = () => {
    const [searchParams] = useSearchParams();

    const navigate = useCustomNavigate()

    useEffect(() => {
        const url = searchParams.get('url');

        if (REDIRECT_KEY_TO_PATH[url]) {
            navigate(REDIRECT_KEY_TO_PATH[url])
        } else {
            navigate('dashboard')
        }

    }, [searchParams, navigate]);

    return null
};

export default Redirect;
