import React, { useEffect, useMemo, useRef, useState } from 'react'
import { OverlayTrigger, Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';

import { DASHBOARD_SORT_VALUES_TRANSLATIONS, USER_QR_TYPE_TRANSLATIONS } from '../../helpers/translation-constants';
import { useCustomLoggerMutation, useGetOverviewCodeStatsQuery } from '../../api/api'
import { CODE_TYPE_TO_NAME, SORT_VALUES_TO_NAME } from '../../helpers/constants'
import useDateTranslations from '../../hooks/useDateTranslations';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import useReturnCodeSvg from '../../hooks/useReturnCodeSvg'
import { useViewport } from '../../hooks/useViewport';
import { convertDate } from '../../helpers/functions'

import ViewFullscreenCodeModal from '../ViewFullscreenCodeModal/ViewFullscreenCodeModal';

import overviewPanelConvertThunder from '../../assets/icons/overview-panel-convert-thunder.svg'
import overviewPanelMobileBack from '../../assets/icons/overview-panel-mobile-back.svg'
import overviewNextPrevArrow from '../../assets/icons/overview-next-prev-arrow.svg'
import dashboardMoreOptions from '../../assets/icons/dashboard-more-options.svg'
import dashboardEditPencil from '../../assets/icons/dashboard-edit-pencil.svg'
import overviewPanelClose from '../../assets/icons/overview-panel-close.svg'
import dashboardDownload from '../../assets/icons/dashboard-download.svg'
import overviewRedirect from '../../assets/icons/overview-redirect.svg'
import dashboardDelete from '../../assets/icons/dashboard-delete.svg'
import confirmArchive from '../../assets/icons/confirm-archive.svg'
import dashboardPause from '../../assets/icons/dashboard-pause.svg'
import confirmDelete from '../../assets/icons/confirm-delete.svg'
import EmbedCodeModal from './EmbedCodeModal';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const OverviewPanel = ({
    trialDaysLeft,
    downloadCode,
    code,
    handleEditQrCode,
    handleArchiveDynamicCode,
    handleDeleteCode,
    handleArchiveCode,
    setOverviewCode,
    handleConvertData,
    setArchiveId,
    handleArchiveCodeConfirmed,
    handleDeleteCodeConfirmed,
    setDeleteId,
    isFullPage = false,
}) => {
    const [showMobileOptions, setShowMobileOptions] = useState(false)
    const [showMobileArchiveConfirm, setShowMobileArchiveConfirm] = useState(false)
    const [showMobileDeleteConfirm, setShowMobileDeleteConfirm] = useState(false)
    const [showFullscreen, setShowFullscreen] = useState(false)
    const [showEmbedCode, setShowEmbedCode] = useState(false)
    const [uniqueScans, setUniqueScans] = useState(false)
    const [currentPeriod, setCurrentPeriod] = useState(0)

    const { months, translateAnalyticsDate } = useDateTranslations()
    const { dynamicBlockIcons } = useReturnCodeSvg()
    const { isMobile } = useViewport()
    const { t } = useTranslation()

    const overviewPanelRef = useRef(null)
    const mobileOptionsRef = useRef(null)

    const navigate = useCustomNavigate()

    const [customLogger] = useCustomLoggerMutation()
    const { data: codeStats } = useGetOverviewCodeStatsQuery({
        id: code?.id,
        unique: uniqueScans,
    }, { skip: !code?.id || code?.type === 'static' })

    const options = useMemo(() => {
        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top',
                    display: false,
                },
                title: {
                    display: false,
                    text: 'Chart.js Line Chart',
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                        },
                    }],
                },
                tooltip: {
                    intersect: isMobile ? false : true,
                    borderColor: '#E2E8F0',
                    backgroundColor: '#FFFFFF',
                    titleColor: '#000000',
                    titleFont: {
                        size: 28,
                        weight: 700,
                        lineHeight: 0.9,
                    },
                    bodyColor: '#000000',
                    bodyFont: {
                        size: 16,
                        weight: 600,
                    },
                    footerColor: '#7D8898',
                    footerFont: {
                        size: 16,
                        weight: 400,
                    },
                    borderWidth: 1,
                    callbacks: {
                        title: function (context) {
                            return `${context[0]?.formattedValue}`
                        },
                        beforeBody: function () {
                            return uniqueScans ? "unique scans" : "total scans";
                        },
                        beforeFooter: function (context) {
                            return `${context[0]?.label}`;
                        },
                        label: function () {
                            return null
                        }
                    },
                },
            },
            elements: {
                point: {
                    pointBackgroundColor: '#FFFFFF',
                    radius: 7,
                    hoverRadius: 7,
                    pointHoverBackgroundColor: '#BEC5CF',
                    pointBorderColor: '#BEC5CF',
                    pointBorderWidth: 2,
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: '#BEC5CF',
                        font: {
                            size: '12'
                        },
                        maxRotation: 0,
                        minRotation: 0,
                        display: true,
                    },
                    border: {
                        display: true,
                        font: {
                            family: 'Inter',
                            size: '12',
                            weight: '600',
                            lineHeight: '18px'
                        }
                    }
                },
                y: {
                    grid: {
                        display: true,
                    },
                    ticks: {
                        color: '#BEC5CF',
                        count: 10,
                        display: true,
                    },
                    border: {
                        display: false,
                    },
                    position: 'right',
                    min: 0,
                    offset: true,
                },
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            }
        }
    }, [uniqueScans])

    const labels = useMemo(() => {
        if (codeStats && codeStats?.length > 0) {
            if (isMobile) {
                return ['S', 'M', 'T', 'W', 'TH', 'F', 'S']
            } else {
                return codeStats[currentPeriod]?.data?.map((el) => translateAnalyticsDate(el.title))
            }
        } else {
            return []
        }
    }, [codeStats, currentPeriod, isMobile])

    const dataList = useMemo(() => {
        if (codeStats && codeStats?.length > 0) {
            return codeStats[currentPeriod]?.data.map((el) => el.value)
        } else {
            return []
        }
    }, [codeStats, currentPeriod])

    const data = useMemo(() => {
        return {
            labels,
            datasets: [
                {
                    label: uniqueScans ? 'Unique Scans' : 'Total Scans',
                    lineTension: 0.5,
                    data: dataList,
                    borderColor: '#9747FF',
                },
            ],
        }
    }, [labels, dataList, uniqueScans]);

    const handleConvertCodeAndClose = () => {
        handleConvertData(code)
        setOverviewCode(null)
    }

    const handleViewFullscreen = () => {
        setShowFullscreen(true)
    }

    const handlePrevWeek = () => {
        if (currentPeriod > 0) {
            setCurrentPeriod(currentPeriod - 1)
        }
    }

    const handleNextWeek = () => {
        if (currentPeriod < codeStats?.length - 1) {
            setCurrentPeriod(currentPeriod + 1)
        }
    }

    const seeFullAnalytics = () => {
        navigate(`/dashboard/analytics?code=${code?.name}`)
    }

    const handleOpenEmbedCodeModal = () => {
        setShowEmbedCode(true)
        customLogger({
            action: 'click',
            description: `Open Embed Code Modal for QR Code: ${code.id}`
        })
    }

    useEffect(() => {
        if (codeStats && codeStats?.length > 0) {
            setCurrentPeriod(codeStats.length - 1)
        }
    }, [codeStats])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (mobileOptionsRef.current && !mobileOptionsRef.current.contains(event.target) && !showEmbedCode) {
                setShowMobileOptions(false)
                setShowMobileArchiveConfirm(false)
                setShowMobileDeleteConfirm(false)
                setArchiveId(null)
            } else if (overviewPanelRef.current && !overviewPanelRef.current.contains(event.target) && !showFullscreen && !isFullPage && !showEmbedCode) {
                setOverviewCode(null)
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setArchiveId, setOverviewCode, showFullscreen, isFullPage, showEmbedCode])

    if (isFullPage && !code) return ''

    return (
        <div ref={overviewPanelRef} className={`overview-panel-wrapper ${isFullPage ? 'full-page' : ''}`}>
            <div className='overview-panel-header-options'>
                <div className='overview-panel-header-options-left'>
                    {isFullPage ? (
                        <div onClick={() => navigate(-1)} className='view-fullscreen-code-close cursor-pointer'>
                            <CgClose size={24} color={"#7D8898"} />
                        </div>
                    ) : (
                        <>
                            {isMobile ? (
                                <div onClick={() => setOverviewCode(null)} className='dashboard-options-icon-wrapper'>
                                    <img src={overviewPanelMobileBack} alt="" />
                                </div>
                            ) : (
                                <>
                                    {code?.type === 'static' && (
                                        <button onClick={handleConvertCodeAndClose} className='overview-panel-header-options-convert-button'>{t("convertToDynamic")}</button>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className='overview-panel-header-options-right'>
                    <button
                        onClick={handleOpenEmbedCodeModal}
                        className='h-[32px] rounded-lg border-solid border-[1px] border-[#E2E8F0] px-[8px] text-[14px] leading-[20px] text-[#141315] font-semibold me-[8px] hover:border-[#141315] transition-colors duration-300'
                    >
                        {t("embed")}
                    </button>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<div className='tooltip-custom'>{t("download")}</div>}
                    >
                        {trialDaysLeft <= 0 ? (
                            <div className='dashboard-options-icon-wrapper disabled'>
                                <img src={dashboardDownload} alt="" />
                            </div>
                        ) : (
                            <div onClick={() => downloadCode(code)} className='dashboard-options-icon-wrapper'>
                                <img src={dashboardDownload} alt="" />
                            </div>
                        )}
                    </OverlayTrigger>
                    {code?.type === 'dynamic' && (
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<div className='tooltip-custom'>{t("edit")}</div>}
                        >
                            {trialDaysLeft <= 0 ? (
                                <div className='dashboard-options-icon-wrapper disabled'>
                                    <img src={dashboardEditPencil} alt="" />
                                </div>
                            ) : (
                                <div onClick={() => handleEditQrCode(code?.id)} className='dashboard-options-icon-wrapper'>
                                    <img src={dashboardEditPencil} alt="" />
                                </div>
                            )}
                        </OverlayTrigger>
                    )}
                    {isMobile ? (
                        <div onClick={() => setShowMobileOptions(true)} className='dashboard-options-icon-wrapper'>
                            <img src={dashboardMoreOptions} alt="" />
                        </div>
                    ) : (
                        <Dropdown>
                            <Dropdown.Toggle disabled={trialDaysLeft <= 0 ? true : false} className='bootstrap-default-dropdown bootstrap-transparent-dropdown dropdown-disabled' id="dropdown-basic">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<div className='tooltip-custom'>{t("options")}</div>}
                                >
                                    <div className='dashboard-options-icon-wrapper'>
                                        <img src={dashboardMoreOptions} alt="" />
                                    </div>
                                </OverlayTrigger>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='dashboard-options-menu-block'>
                                {code?.type === 'dynamic' && (
                                    <Dropdown.Item onClick={() => { code?.type === 'static' ? handleArchiveCode(code) : handleArchiveDynamicCode(code) }} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                        <div className='dashboard-more-options-item-wrapper'>
                                            {code?.archived || code?.data?.paused ? t("unpauseTracking") : t("pauseTracking")}
                                            <img src={dashboardPause} alt="" />
                                        </div>
                                    </Dropdown.Item>
                                )}
                                <Dropdown.Item onClick={() => handleDeleteCode(code?.id)} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                    <div className='dashboard-more-options-item-wrapper'>
                                        {t("deleteCode")}
                                        <img src={dashboardDelete} alt="" />
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    {!isMobile && !isFullPage && (
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<div className='tooltip-custom'>{t("close")}</div>}
                        >
                            <div onClick={() => setOverviewCode(null)} className='dashboard-options-icon-wrapper'>
                                <img src={overviewPanelClose} alt="" />
                            </div>
                        </OverlayTrigger>
                    )}
                </div>
            </div>
            <div className='overview-panel-scrollable-content'>
                <div className='overview-panel-code-info'>
                    <div className='overview-panel-code-info-block'>
                        <h2 className='overview-panel-code-info-name'>{code?.name}</h2>
                        <table className='overview-panel-code-info-table'>
                            <tbody>
                                <tr>
                                    <td className='heading'>{t("codeType")}</td>
                                    <td>
                                        <div className='overview-panel-code-info-type'>
                                            <>
                                                <img width={14} src={dynamicBlockIcons[code?.data?.kind]} alt="" />
                                                {t(USER_QR_TYPE_TRANSLATIONS[CODE_TYPE_TO_NAME[code?.data?.kind]])}
                                            </>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='heading'>{t("codeStatus")}</td>
                                    <td>
                                        <div className='overview-panel-code-info-type'>
                                            {t(DASHBOARD_SORT_VALUES_TRANSLATIONS[SORT_VALUES_TO_NAME[code?.type]])}
                                        </div>
                                    </td>
                                </tr>
                                {code?.type === 'dynamic' && (
                                    <tr>
                                        <td className='heading'>{t("origin")}</td>
                                        <td>
                                            <a className='overview-panel-info-link' href={`${window.location.origin}/code/${code?.public_id}`} target='_blank' rel="noreferrer">
                                                <span style={{ overflow: 'hidden' }}>
                                                    qrcodeveloper/code/{code?.public_id}
                                                </span>
                                                <img src={overviewRedirect} alt="" />
                                            </a>
                                        </td>
                                    </tr>
                                )}
                                {code?.data?.kind === 'website_url' && (
                                    <tr>
                                        <td className='heading'>{t("redirect")}</td>
                                        <td>
                                            <a className='overview-panel-info-link' href={code?.data?.content?.website_url} target='_blank' rel="noreferrer">
                                                <span style={{ overflow: 'hidden' }}>
                                                    {code?.type === 'static' ? code?.data?.content : code?.data?.content?.website_url}
                                                </span>
                                                <img src={overviewRedirect} alt="" />
                                            </a>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td className='heading'>{t("created")}</td>
                                    <td className='default-text'>{convertDate(code?.created_at, months)}</td>
                                </tr>
                                <tr>
                                    <td className='heading'>{t("modified")}</td>
                                    <td className='default-text'>{convertDate(code?.updated_at, months)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='overview-panel-code-info-preview-block'>
                        <img className='overview-panel-code-image-static' src={code?.image} alt="" />
                        <button className='overview-panel-code-view-fullscreen' onClick={handleViewFullscreen}>{t("viewFullscreen")}</button>
                    </div>
                </div>
                <div className='overview-panel-stats-wrapper'>
                    <div className={`overview-panel-stats ${isMobile && code?.type === 'static' ? 'overview-panel-mobile-stats-padding' : 'overview-panel-mobile-stats-padding-small'}`}>
                        {code?.type === 'static' && (
                            <div className='overview-panel-stats-convert-static-block'>
                                <div className='overview-panel-stats-convert-static-block-left'>
                                    <img src={overviewPanelConvertThunder} alt="" />
                                    <span className='overview-panel-stats-convert-static-block-left-text'>
                                        {t("startTrackingAnalyticsByConvertingThisCode")}
                                    </span>
                                </div>
                                <div className='overview-panel-stats-convert-static-block-right'>
                                    <button onClick={handleConvertCodeAndClose} className='overview-panel-stats-convert-static-block-right-button'>
                                        {t("convertToDynamic")}
                                    </button>
                                </div>
                            </div>
                        )}
                        <div className='overview-panel-header-filters-wrapper'>
                            <div className={`overview-panel-stats-header ${code?.type === 'static' ? 'unusable-area' : ''}`}>
                                <h3 className='overview-panel-stats-heading'>{t("weeklyOverview")}</h3>
                                <div className='overview-panel-stats-header-filters'>
                                    <div className='overview-panel-stats-period-filter-wrapper'>
                                        {isMobile ? (
                                            <button onClick={seeFullAnalytics} className={`overview-panel-stats-see-full-button`}>
                                                {t("goToAnalytics")}
                                            </button>
                                        ) : (
                                            <button onClick={seeFullAnalytics} className={`overview-panel-stats-see-full-button`}>
                                                {t("seeFullAnalyticsReport")} -&gt;
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={`overview-panel-stats-header mobile-column ${code?.type === 'static' ? 'unusable-area' : ''}`}>
                                <div className='analytics-header-option-block'>
                                    <span className='analytics-header-option-label'>{t("uniqueScans")}</span>
                                    <div onClick={() => setUniqueScans(!uniqueScans)} className={`overview-panel-stats-header-unique-scans-switch ${uniqueScans ? 'active' : ''}`}>
                                        <div className={`overview-panel-switch-circle ${uniqueScans ? 'active' : ''}`}></div>
                                    </div>
                                </div>
                                <div className='overview-panel-stats-header-filters'>
                                    <div className='overview-panel-stats-period-week-actions-wrapper'>
                                        <span className='overview-panel-stats-period-current'>
                                            {codeStats && codeStats.length > 0 ? translateAnalyticsDate(codeStats[currentPeriod]?.title) : ''}
                                        </span>
                                        <div className='overview-panel-stats-period-week-next-prev-block'>
                                            <button
                                                disabled={currentPeriod === 0 ? true : false}
                                                onClick={handlePrevWeek}
                                                className='overview-panel-stats-week-next-prev-btn'
                                            >
                                                <img src={overviewNextPrevArrow} alt="" />
                                            </button>
                                            <button disabled={currentPeriod >= codeStats?.length - 1} onClick={handleNextWeek} className='overview-panel-stats-week-next-prev-btn'>
                                                <img style={{ transform: `rotate(180deg)` }} src={overviewNextPrevArrow} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`overview-panel-stats-tables ${code?.type === 'static' ? 'unusable-area' : ''}`}>
                            <div className='overview-panel-stats-total-scans-block'>
                                <div className='overview-panel-stats-total-scans-block-header'>
                                    <span className='overview-panel-stats-total-scans-block-heading'>{uniqueScans ? t("uniqueScans") : t("totalScansGraph")}</span>
                                    <div className='overview-panel-stats-total-scans-total-numbers'>
                                        {code?.data?.scans > 0 ? (
                                            <span className='overview-panel-stats-total-scans-count'>{codeStats ? codeStats[currentPeriod]?.total_scans : ''}</span>
                                        ) : code?.type === 'static' && (
                                            <span style={{ color: '#BEC5CF', opacity: '0.6' }} className='overview-panel-stats-total-scans-count'>--</span>
                                        )}
                                    </div>
                                </div>
                                <div className='overview-panel-stats-total-scans-graph'>
                                    {data && data?.labels?.length > 0 && code?.data?.scans > 0 ? (
                                        <Line style={{ minHeight: '250px' }} options={options} data={data} />
                                    ) : (
                                        <div className='overview-panel-graph-scans-no-data'>{t("noScans")}</div>
                                    )}
                                </div>
                            </div>
                            <div className='overview-panel-stats-scans-by-category-two-cols'>
                                <div className='overview-panel-stats-scans-by-category-column'>
                                    <span className='overview-panel-stats-scans-by-category-column-title'>{isMobile ? t("total") : ''} {t("scansByOperatingSystem")}</span>
                                    {(codeStats && codeStats[currentPeriod]?.os?.length > 0) || code?.type === 'static' ? (
                                        <table className='overview-panel-stats-scans-by-category-table'>
                                            <tbody>
                                                {code?.type === 'dynamic' ? (
                                                    <>
                                                        {codeStats[currentPeriod].os.map((scan, idx) => (
                                                            <tr key={idx} className='scans-table-row'>
                                                                <td>{scan.name}</td>
                                                                <td className='overview-panel-count-scans'>{scan.count}</td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr className='scans-table-row'>
                                                            <td>Apple</td>
                                                            <td className='scans-table-row-static-value'>--</td>
                                                        </tr>
                                                        <tr className='scans-table-row'>
                                                            <td>Android</td>
                                                            <td className='scans-table-row-static-value'>--</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className='overview-panel-graph-no-data'>{t("noScans")}</div>
                                    )}
                                </div>
                                <div className='overview-panel-stats-scans-by-category-column'>
                                    <span className='overview-panel-stats-scans-by-category-column-title'>{isMobile ? t("total") : ''} {t("scansByBrowser")}</span>
                                    {(codeStats && codeStats[currentPeriod]?.browser?.length > 0) || code?.type === 'static' ? (
                                        <table className='overview-panel-stats-scans-by-category-table'>
                                            <tbody>
                                                {code?.type === 'dynamic' ? (
                                                    <>
                                                        {
                                                            codeStats[currentPeriod]?.browser.map((scan, idx) => (
                                                                <tr key={idx} className='scans-table-row'>
                                                                    <td>{scan.name}</td>
                                                                    <td className='overview-panel-count-scans'>{scan.count}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr className='scans-table-row'>
                                                            <td>Chrome</td>
                                                            <td className='scans-table-row-static-value'>--</td>
                                                        </tr>
                                                        <tr className='scans-table-row'>
                                                            <td>Safari</td>
                                                            <td className='scans-table-row-static-value'>--</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className='overview-panel-graph-no-data'>{t("noScans")}</div>
                                    )}
                                </div>
                            </div>
                            <div className='overview-panel-stats-scans-by-category-two-cols'>
                                <div className='overview-panel-stats-scans-by-category-column'>
                                    <span className='overview-panel-stats-scans-by-category-column-title'>{isMobile ? t("total") : ''} {t("scansByCountry")}</span>
                                    {(codeStats && codeStats[currentPeriod]?.country?.length > 0) || code?.type === 'static' ? (
                                        <table className='overview-panel-stats-scans-by-category-table'>
                                            <tbody>
                                                {code?.type === 'dynamic' ? (
                                                    <>
                                                        {codeStats[currentPeriod]?.country.map((scan, idx) => (
                                                            <tr key={idx} className='scans-table-row'>
                                                                <td>{scan.name}</td>
                                                                <td className='overview-panel-count-scans'>{scan.count}</td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr className='scans-table-row'>
                                                            <td>United States of America</td>
                                                            <td className='scans-table-row-static-value'>--</td>
                                                        </tr>
                                                        <tr className='scans-table-row'>
                                                            <td>United Kingdom</td>
                                                            <td className='scans-table-row-static-value'>--</td>
                                                        </tr>
                                                        <tr className='scans-table-row'>
                                                            <td>Japan</td>
                                                            <td className='scans-table-row-static-value'>--</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className='overview-panel-graph-no-data'>{t("noScans")}</div>
                                    )}
                                </div>
                                <div className='overview-panel-stats-scans-by-category-column'>
                                    <span className='overview-panel-stats-scans-by-category-column-title'>{isMobile ? t("total") : ''} {t("scansByCity")}</span>
                                    {(codeStats && codeStats[currentPeriod]?.city?.length > 0) || code?.type === 'static' ? (
                                        <table className='overview-panel-stats-scans-by-category-table'>
                                            <tbody>
                                                {code?.type === 'dynamic' ? (
                                                    <>
                                                        {codeStats[currentPeriod]?.city.map((scan, idx) => (
                                                            <tr key={idx} className='scans-table-row'>
                                                                <td>{scan.name}</td>
                                                                <td className='overview-panel-count-scans'>{scan.count}</td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr className='scans-table-row'>
                                                            <td>New York</td>
                                                            <td className='scans-table-row-static-value'>--</td>
                                                        </tr>
                                                        <tr className='scans-table-row'>
                                                            <td>London</td>
                                                            <td className='scans-table-row-static-value'>--</td>
                                                        </tr>
                                                        <tr className='scans-table-row'>
                                                            <td>Tokyo</td>
                                                            <td className='scans-table-row-static-value'>--</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className='overview-panel-graph-no-data'>{t("noScans")}</div>
                                    )}
                                </div>
                            </div>
                            <div className='overview-panel-stats-scans-by-category-two-cols'>
                                <div className='overview-panel-stats-scans-by-category-column'>
                                    <span className='overview-panel-stats-scans-by-category-column-title'>{isMobile ? t("total") : ''} {t("scansByLanguage")}</span>
                                    {(codeStats && codeStats[currentPeriod]?.language?.length > 0) || code?.type === 'static' ? (
                                        <table className='overview-panel-stats-scans-by-category-table'>
                                            <tbody>
                                                {code?.type === 'dynamic' ? (
                                                    <>
                                                        {codeStats[currentPeriod].language.map((scan, idx) => (
                                                            <tr key={idx} className='scans-table-row'>
                                                                <td>{scan.name}</td>
                                                                <td className='overview-panel-count-scans'>{scan.count}</td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr className='scans-table-row'>
                                                            <td>English</td>
                                                            <td className='scans-table-row-static-value'>--</td>
                                                        </tr>
                                                        <tr className='scans-table-row'>
                                                            <td>Japanese</td>
                                                            <td className='scans-table-row-static-value'>--</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className='overview-panel-graph-no-data'>{t("noScans")}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {
                showMobileOptions && (
                    <div style={{ zIndex: '101' }} className='link-filter-background'>
                        <div ref={mobileOptionsRef} className='link-filter-block'>
                            {showMobileArchiveConfirm || showMobileDeleteConfirm ? (
                                <>
                                    <div onClick={() => { setShowMobileOptions(false); setShowMobileArchiveConfirm(false); setShowMobileDeleteConfirm(false) }} className='mobile-tray-horizontal-bar'>
                                        <div className='mobile-tray-horizontal-bar-element'></div>
                                    </div>
                                    {showMobileArchiveConfirm ? (
                                        <div className='mobile-confirm-tray'>
                                            <img className='confirm-modal-icon' src={confirmArchive} alt="" />
                                            <h5 className='confirm-modal-title'>{t("areYouSureYouWantTo")} {code?.archived ? t("unpause") : t("pause")} {t("tracking")}?</h5>
                                            <div style={{ flexDirection: 'column' }} className='confirm-modal-actions-block'>
                                                <button onClick={handleArchiveCodeConfirmed} className='confirm-modal-action-confirm'>
                                                    {code?.archived ? t("unpauseTracking") : t("pauseTracking")}
                                                </button>
                                                <button onClick={() => setShowMobileArchiveConfirm(false)} className='confirm-modal-action-cancel'>{t("cancel")}</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='mobile-confirm-tray'>
                                            <img className='confirm-modal-icon' src={confirmDelete} alt="" />
                                            <h5 className='confirm-modal-title'>{t("areYouSureYouWantToDeleteThisQRCode")}</h5>
                                            <div style={{ flexDirection: 'column' }} className='confirm-modal-actions-block'>
                                                <button onClick={handleDeleteCodeConfirmed} className='confirm-modal-action-delete'>{t("deleteQRCode")}</button>
                                                <button onClick={() => setShowMobileDeleteConfirm(false)} className='confirm-modal-action-cancel'>{t("cancel")}</button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div onClick={() => { setShowMobileOptions(false); setShowMobileArchiveConfirm(false); setShowMobileDeleteConfirm(false) }} className='mobile-tray-horizontal-bar'>
                                        <div className='mobile-tray-horizontal-bar-element'></div>
                                    </div>
                                    <div className='mobile-filter-expand-block-header'>
                                        <span style={{ color: '#141315' }} className='mobile-filter-expand-block-title'>{t("options")}</span>
                                    </div>
                                    <span className='link-filter-title-underline'></span>
                                    {code?.type === 'static' ? (
                                        <>
                                            <button onClick={() => { setShowMobileArchiveConfirm(true); setArchiveId(code) }} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                                <div className='dashboard-more-options-item-wrapper'>
                                                    <img src={dashboardPause} alt="" />
                                                    {code?.archived ? t("unpauseTracking") : t("pauseTracking")}
                                                </div>
                                            </button>
                                            <button onClick={() => { setShowMobileDeleteConfirm(true); setDeleteId(code?.id) }} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                                <div style={{ borderBottom: '0px' }} className='dashboard-more-options-item-wrapper'>
                                                    <img src={dashboardDelete} alt="" />
                                                    {t("deleteCode")}
                                                </div>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={() => { setShowMobileArchiveConfirm(true); setArchiveId(code) }} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                                <div className='dashboard-more-options-item-wrapper'>
                                                    <img src={dashboardPause} alt="" />
                                                    {code?.archived ? t("unpauseTracking") : t("pauseTracking")}
                                                </div>
                                            </button>
                                            <button onClick={() => { setShowMobileDeleteConfirm(true); setDeleteId(code?.id) }} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                                <div style={{ borderBottom: '0px' }} className='dashboard-more-options-item-wrapper'>
                                                    <img src={dashboardDelete} alt="" />
                                                    {t("deleteCode")}
                                                </div>
                                            </button>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )
            }
            {showFullscreen && (
                <ViewFullscreenCodeModal
                    code={code}
                    show={showFullscreen}
                    onClose={() => setShowFullscreen(false)}
                    downloadCode={downloadCode}
                />
            )}
            {showEmbedCode && (
                <EmbedCodeModal
                    code={code}
                    show={showEmbedCode}
                    onClose={() => setShowEmbedCode(false)}
                />
            )}
        </div>
    )
}

export default OverviewPanel