import { IoCloseOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import React, { useRef } from "react";

import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { useCustomLoggerMutation } from "../../api/api";
import { useViewport } from "../../hooks/useViewport";

import Thunder from '../../assets/icons/thunder.svg'
import Lock from '../../assets/icons/lock.svg'

const QRCodesLimitReachedModal = ({ show, onClose, codesLength }) => {
    const rootRef = useRef(null)

    const navigate = useCustomNavigate()

    const { t } = useTranslation()
    const { isMobile } = useViewport()

    const [customLogger] = useCustomLoggerMutation()

    const handleNavigateToUpgradePlans = () => {
        customLogger({
            action: 'click',
            description: `User clicked on "Create Unlimited Codes" button in QR Codes Limit Reached Modal`
        })
        navigate('/upgrade-plan-new')
    }

    return <Modal
        show={show}
        size="md"
        popup="false"
        position='center'
        onClose={onClose}
        style={{ height: '100vh' }}
        className='create-code-help-modal'
        root={rootRef.current ?? undefined}
    >
        <Modal.Body className='w-full bg-[#FFFFFF] rounded-[24px] p-0'>
            <div
                onClick={onClose}
                className='absolute w-[32px] h-[32px] bg-[#FFFFFF] right-[-40px] top-[0] rounded-full flex items-center justify-center cursor-pointer mobile:right-[16px] mobile:top-[16px] mobile:bg-[#1B003E]'
            >
                <IoCloseOutline
                    size={20}
                    color={isMobile ? '#FFFFFF' : '#141315'}
                />
            </div>
            <div className="rounded-[24px] overflow-hidden flex flex-col">
                <div className="bg-[#E0C8FF] w-full h-[148px] d-flex items-center justify-center">
                    <img className="w-[100px]" src={codesLength && codesLength >= 3 ? Lock : Thunder} alt="" />
                </div>
                <div className="p-6 d-flex flex-col gap-4 items-center">
                    <div className="w-full flex flex-col gap-2 items-center">
                        <h5 className="text-[#141315] text-[23px] leading-[28px] tracking-[-0.02em] font-bold text-center">
                            {codesLength === 2 ? t("youAreRunningLowOnFreeCodesToCreate") : t("oopsYouHaveRunOutOfFreeCodesToCreate")}
                        </h5>
                        <p className="text-[#7D8897] text-[16px] leading-[23px] text-center">
                            {t("upgradeNowToCreateUnlimitedCodes")}
                        </p>
                    </div>
                    <button onClick={handleNavigateToUpgradePlans} className="bg-[#9747FF] h-[40px] rounded-lg text-white w-full font-semibold hover:bg-[#7f2fe7] transition-colors duration-300">
                        {t("createUnlimitedCodes")}
                    </button>
                </div>
            </div>
        </Modal.Body>
    </Modal>
};

export default QRCodesLimitReachedModal;
