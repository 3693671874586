import { useTranslation } from 'react-i18next'

import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import { useCustomLoggerMutation } from '../../api/api'

const TrialPeriodBlock = ({ isNewUser, codesLength }) => {
    const navigate = useCustomNavigate()

    const { t } = useTranslation()

    const [customLogger] = useCustomLoggerMutation()

    const handleNavigateToUpgrade = () => {
        if (isNewUser) {
            navigate('/upgrade-plan-new')
        } else {
            navigate('/upgrade-plan')
        }
        customLogger({
            action: 'click',
            description: 'Dashboard Upgrade Banner - Get Unlimited'
        })
    }

    return (
        <div className={`w-full min-h-[52px] trial-period-radial-gradient flex items-center justify-between ps-[16px] pe-[8px] gap-[16px] py-[8px] mobile:flex-col mobile:gap-[4px] h-fit mobile:items-start mobile:px-[16px] mobile:py-[16px] mobile:min-h-[unset]`}>
            <div className='flex flex-col'>
                {codesLength && codesLength >= 3 ? (
                    <>
                        <h5 className='text-base text-white font-semibold'>
                            {t("youHaveReachedYourFreeTrialLimitOf2Codes")}
                        </h5>
                        <p className='text-white text-[14px] leading-[20px] mobile:hidden'>
                            {t("upgradeToCreateMore")}
                        </p>
                    </>
                ) : (
                    <h5 className='text-base text-white font-semibold'>
                        {t("yourFreeAccountIsActive")}
                    </h5>
                )}
            </div>
            <div className='flex items-center gap-[16px] mobile:flex-col mobile:w-full mobile:items-start'>
                <div className='flex items-center gap-2 mobile:w-full mobile:justify-between'>
                    <p className='text-white font-semibold text-[12px] leading-[17px] mobile:font-normal'>
                        {t("amountOfTwoFreeCodesCreated", { amount: codesLength ? codesLength > 3 ? 3 : codesLength : 0 })}
                    </p>
                    <div className='flex items-center gap-1'>
                        <span
                            style={{
                                backgroundColor: `${codesLength > 0 ? '#FFFFFF' : '#1B003E'}`,
                                opacity: `${codesLength > 0 ? 1 : 0.35}`
                            }}
                            className='w-[40px] h-[6px] rounded-full'
                        ></span>
                        <span
                            style={{
                                backgroundColor: `${codesLength > 1 ? '#FFFFFF' : '#1B003E'}`,
                                opacity: `${codesLength > 1 ? 1 : 0.35}`
                            }}
                            className='w-[40px] h-[6px] rounded-full'
                        ></span>
                        <span
                            style={{
                                backgroundColor: `${codesLength > 2 ? '#FFFFFF' : '#1B003E'}`,
                                opacity: `${codesLength > 2 ? 2 : 0.35}`
                            }}
                            className='w-[40px] h-[6px] rounded-full'
                        ></span>
                    </div>
                </div>
                <button onClick={handleNavigateToUpgrade} className='bg-[#FFFFFF] rounded-lg px-3 h-[36px] text-[14px] leading-[17px] font-semibold text-[#141315] mobile:w-full'>
                    {t("getUnlimited")}
                </button>
            </div>
        </div>
    )
}

export default TrialPeriodBlock