import React, { useCallback, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'

import { useCustomNavigate } from '../../hooks/useCustomNavigate'

import CreateCodeHelpModal from '../../components/DashboardComponents/CreateCodeHelpModal'
import MobileCreationFlow from '../../components/MobileCreationFlow/MobileCreationFlow'
import UserCreateCode from '../../components/DashboardComponents/UserCreateCode'

const NewCreationDashboard = () => {
    const [showHelpModal, setShowHelpModal] = useState(false)
    const [isValid, setIsValid] = useState(true)

    const triggerValidationRef = useRef(null)

    const navigate = useCustomNavigate()

    const triggerValidation = useCallback((trigger) => {
        if (trigger) {
            triggerValidationRef.current = trigger
        }
    }, [])

    const handleTriggerAndGenerate = async () => {
        if (triggerValidationRef.current) {
            const result = await triggerValidationRef.current()

            if (result) {
                navigate('/sign-up?upgrade=true&download=true')
                window.gtag('event', 'land_on_sign_up_page', {
                    type: 'qr_code_creation',
                })
            }
        }
    }

    return (
        <div style={{ backgroundColor: 'white' }} className='user-dashboard-content-new-home'>
            <Helmet>
                <link rel="canonical" href="https://qrcodeveloper.com/generate-code" />
                <link rel="alternate" href="https://qrcodeveloper.com/pt/generate-code" hreflang="pt"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/de/generate-code" hreflang="de"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/es/generate-code" hreflang="es"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/fr/generate-code" hreflang="fr"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/dk/generate-code" hreflang="dk"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/it/generate-code" hreflang="it"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/hu/generate-code" hreflang="hu"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/mx/generate-code" hreflang="mx"></link>
            </Helmet>
            <UserCreateCode
                handleTriggerAndGenerate={handleTriggerAndGenerate}
                triggerValidation={triggerValidation}
                setIsValid={setIsValid}
                isValid={isValid}
                showHelpModal={showHelpModal}
                setShowHelpModal={setShowHelpModal}
            />
            <MobileCreationFlow
                handleConvertData={() => { }}
                isNewHome={true}
                setShowHelpModal={setShowHelpModal}
                showHelpModal={showHelpModal}
            />
            {showHelpModal && (
                <CreateCodeHelpModal
                    show={showHelpModal}
                    onClose={() => setShowHelpModal(false)}
                />
            )}
        </div>
    )
}

export default NewCreationDashboard