import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const useAccountNewPlans = () => {
    const subscription = useSelector((state) => state.app.subscription);

    const { t } = useTranslation();

    const accountNewPlans = useMemo(() => {
        if (subscription?.increased_prices) {
            return [
                {
                    name: t("14daysLimitedAccess"),
                    nameEnglish: "14-Day Limited Access",
                    shortName: 'Limited',
                    price_per_period: '',
                    best: false,
                    price_id: 'price_1QUOBGG2gC76wG4Chle8PpDF,price_1QUOBFG2gC76wG4C5kIqaVDe',
                    note: t("14daysLimitedAccessNoteIncreased"),
                    isAfterTrial: false,
                    yearlyPrice: {
                        en: '1.45',
                        pt: '6.95',
                        de: '1.45',
                        es: '1.45',
                        fr: '1.45',
                        dk: '9.95',
                        it: '1.45',
                        hu: '499',
                        mx: '29.95',
                        portugal: '1.45',
                    },
                    price: {
                        'en': '1.45',
                        'pt': '6.95',
                        'de': '1.45',
                        'es': '1.45',
                        'fr': '1.45',
                        'dk': '9.95',
                        'it': '1.45',
                        'hu': '499',
                        'mx': '29.95',
                        'portugal': '1.45',
                    },
                    paypal_plan_id: {
                        'en': 'P-4SS69505FE722334JM53DU5I',
                        'pt': 'P-5RE87067XN462391GMZLNBTI',
                        'de': 'P-7H41663855246825TM25RJEY',
                        'es': 'P-7H41663855246825TM25RJEY',
                        'fr': 'P-7H41663855246825TM25RJEY',
                        'dk': 'P-2A6629775A095504VM3L77VA',
                        'it': 'P-7H41663855246825TM25RJEY',
                        'hu': 'P-16J867042K437250EM3L76LY',
                        'mx': 'P-3DG74162MT3244411M4JCA5Q',
                        'portugal': 'P-7H41663855246825TM25RJEY',
                    },
                    options: [
                        {
                            title: t("unlimitedStaticQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeQRCodeDesigns"),
                            available: true,
                        },
                        {
                            title: t("unlimitedDynamicQRCodes"),
                            available: false,
                        },
                        {
                            title: t("customizeLandingPageDesigns"),
                            available: false,
                        },
                        {
                            title: t("scansAnalytics"),
                            available: false,
                        },
                        {
                            title: t("allDownloadFormatOptions"),
                            available: false,
                        },
                    ]
                },
                {
                    name: t("14daysFullAccess"),
                    nameEnglish: "14-Day Full Access",
                    shortName: 'Full',
                    price_per_period: '',
                    best: true,
                    price_id: 'price_1QUOBFG2gC76wG4COTQ5pHmv,price_1QUOBFG2gC76wG4CVHJWcWda',
                    note: t("14daysLimitedAccessNoteIncreased"),
                    isAfterTrial: false,
                    yearlyPrice: {
                        en: '1.95',
                        pt: '9.95',
                        de: '1.95',
                        es: '1.95',
                        fr: '1.95',
                        dk: '13.95',
                        it: '1.95',
                        hu: '699',
                        mx: '39.95',
                        portugal: '1.95',
                    },
                    price: {
                        'en': '1.95',
                        'pt': '9.95',
                        'de': '1.95',
                        'es': '1.95',
                        'fr': '1.95',
                        'dk': '13.95',
                        'it': '1.95',
                        'hu': '699',
                        'mx': '39.95',
                        'portugal': '1.95',
                    },
                    paypal_plan_id: {
                        'en': 'P-6DN41247JB5835807M5L7OBA',
                        'pt': 'P-4LC32466K3622815RMZLM63I',
                        'de': 'P-8HX76460Y7475523BM25RKMQ',
                        'es': 'P-8HX76460Y7475523BM25RKMQ',
                        'fr': 'P-8HX76460Y7475523BM25RKMQ',
                        'dk': 'P-0GD30837VA912800TM3L73QQ',
                        'it': 'P-8HX76460Y7475523BM25RKMQ',
                        'hu': 'P-71N87803YN419212PM3L744I',
                        'mx': 'P-52N20447AP089524WM4JCBTY',
                        'portugal': 'P-8HX76460Y7475523BM25RKMQ',
                    },
                    options: [
                        {
                            title: t("unlimitedStaticQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeQRCodeDesigns"),
                            available: true,
                        },
                        {
                            title: t("unlimitedDynamicQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeLandingPageDesigns"),
                            available: true,
                        },
                        {
                            title: t("scansAnalytics"),
                            available: true,
                        },
                        {
                            title: t("allDownloadFormatOptions"),
                            available: true,
                        },
                    ]
                },
                {
                    name: t("unlimitedPlan"),
                    nameEnglish: "Unlimited Plan",
                    shortName: 'Unlimited',
                    price_per_period: t("slashMonth"),
                    best: false,
                    price_id: 'price_1QUOBEG2gC76wG4CJpMnyCsc',
                    note: t("renewsEveryMonthYouMayCancelAnytime"),
                    isAfterTrial: true,
                    yearlyPrice: {
                        en: '38.95',
                        pt: '149.95',
                        de: '28.95',
                        es: '28.95',
                        fr: '28.95',
                        dk: '199.95',
                        it: '28.95',
                        hu: '10699',
                        mx: '595.95',
                        portugal: '28.95',
                    },
                    price: {
                        'en': '38.95',
                        'pt': '149.95',
                        'de': '28.95',
                        'es': '28.95',
                        'fr': '28.95',
                        'dk': '199.95',
                        'it': '28.95',
                        'hu': '10699',
                        'mx': '595.95',
                        'portugal': '28.95',
                    },
                    paypal_plan_id: {
                        'en': 'P-9H6861110Y607014RM5L7Q6A',
                        'pt': 'P-0LA79173GA657594VMZLNGJQ',
                        'de': 'P-0JK17181J6235622LM25RHXI',
                        'es': 'P-0JK17181J6235622LM25RHXI',
                        'fr': 'P-0JK17181J6235622LM25RHXI',
                        'dk': 'P-0874093121496891GM3MAAUQ',
                        'it': 'P-0JK17181J6235622LM25RHXI',
                        'hu': 'P-5JK96774LV3922111M3MABLQ',
                        'mx': 'P-9G096934B35255449M4JBYAI',
                        'portugal': 'P-0JK17181J6235622LM25RHXI',
                    },
                    options: [
                        {
                            title: t("unlimitedStaticQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeQRCodeDesigns"),
                            available: true,
                        },
                        {
                            title: t("unlimitedDynamicQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeLandingPageDesigns"),
                            available: true,
                        },
                        {
                            title: t("scansAnalytics"),
                            available: true,
                        },
                        {
                            title: t("allDownloadFormatOptions"),
                            available: true,
                        },
                    ]
                },
                {
                    name: t("yearlyPlan"),
                    nameEnglish: "Yearly Plan",
                    shortName: 'Yearly',
                    price_per_period: t("slashMonth"),
                    best: false,
                    price_id: 'price_1QUOBEG2gC76wG4CTeVYSISL',
                    note: t("renewsEveryYearYouMayCancelAnytime"),
                    isAfterTrial: 'both',
                    yearlyPrice: {
                        en: '228',
                        pt: '925.95',
                        de: '179.40',
                        es: '179.40',
                        fr: '179.40',
                        dk: '1199.95',
                        it: '179.40',
                        hu: '65599',
                        mx: '3495.95',
                        portugal: '179.40',
                    },
                    price: {
                        'en': '19',
                        'pt': '77.10',
                        'de': '14.95',
                        'es': '14.95',
                        'fr': '14.95',
                        'dk': '99.95',
                        'it': '14.95',
                        'hu': '5499',
                        'mx': '291',
                        'portugal': '14.95',
                    },
                    paypal_plan_id: {
                        'en': 'P-66889183P0087261DM5L7QRA',
                        'pt': 'P-8SU09002P7245240LMZLM27I',
                        'de': 'P-10068147TW535905MM25RLGI',
                        'es': 'P-10068147TW535905MM25RLGI',
                        'fr': 'P-10068147TW535905MM25RLGI',
                        'dk': 'P-0XY325601C5545827M3L7YWQ',
                        'it': 'P-10068147TW535905MM25RLGI',
                        'hu': 'P-8SV64754VR749811MM3L7X3A',
                        'mx': 'P-0WV84734TP085551YM4JB6OA',
                        'portugal': 'P-10068147TW535905MM25RLGI',
                    },
                    options: [
                        {
                            title: t("unlimitedStaticQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeQRCodeDesigns"),
                            available: true,
                        },
                        {
                            title: t("unlimitedDynamicQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeLandingPageDesigns"),
                            available: true,
                        },
                        {
                            title: t("scansAnalytics"),
                            available: true,
                        },
                        {
                            title: t("allDownloadFormatOptions"),
                            available: true,
                        },
                    ]
                },
            ]
        } else {
            return [
                {
                    name: t("14daysLimitedAccess"),
                    nameEnglish: "14-Day Limited Access",
                    shortName: 'Limited',
                    price_per_period: '',
                    best: false,
                    price_id: 'price_1QBBKfG2gC76wG4C460GCrTH,price_1QBBKfG2gC76wG4C0jRx6PjZ',
                    note: t("14daysLimitedAccessNote"),
                    isAfterTrial: false,
                    yearlyPrice: {
                        en: '1.45',
                        pt: '6.95',
                        de: '1.45',
                        es: '1.45',
                        fr: '1.45',
                        dk: '9.95',
                        it: '1.45',
                        hu: '499',
                        mx: '29.95',
                        portugal: '1.45',
                    },
                    price: {
                        'en': '1.45',
                        'pt': '6.95',
                        'de': '1.45',
                        'es': '1.45',
                        'fr': '1.45',
                        'dk': '9.95',
                        'it': '1.45',
                        'hu': '499',
                        'mx': '29.95',
                        'portugal': '1.45',
                    },
                    paypal_plan_id: {
                        'en': 'P-4XH90752X3428450TMVUYINI',
                        'pt': 'P-5RE87067XN462391GMZLNBTI',
                        'de': 'P-7H41663855246825TM25RJEY',
                        'es': 'P-7H41663855246825TM25RJEY',
                        'fr': 'P-7H41663855246825TM25RJEY',
                        'dk': 'P-2A6629775A095504VM3L77VA',
                        'it': 'P-7H41663855246825TM25RJEY',
                        'hu': 'P-16J867042K437250EM3L76LY',
                        'mx': 'P-3DG74162MT3244411M4JCA5Q',
                        'portugal': 'P-7H41663855246825TM25RJEY',
                    },
                    options: [
                        {
                            title: t("unlimitedStaticQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeQRCodeDesigns"),
                            available: true,
                        },
                        {
                            title: t("unlimitedDynamicQRCodes"),
                            available: false,
                        },
                        {
                            title: t("customizeLandingPageDesigns"),
                            available: false,
                        },
                        {
                            title: t("scansAnalytics"),
                            available: false,
                        },
                        {
                            title: t("allDownloadFormatOptions"),
                            available: false,
                        },
                    ]
                },
                {
                    name: t("14daysFullAccess"),
                    nameEnglish: "14-Day Full Access",
                    shortName: 'Full',
                    price_per_period: '',
                    best: true,
                    price_id: 'price_1QBBKfG2gC76wG4CTFeTOc3y,price_1QBBKeG2gC76wG4CXybJsXCu',
                    note: t("14daysLimitedAccessNote"),
                    isAfterTrial: false,
                    yearlyPrice: {
                        en: '1.95',
                        pt: '9.95',
                        de: '1.95',
                        es: '1.95',
                        fr: '1.95',
                        dk: '13.95',
                        it: '1.95',
                        hu: '699',
                        mx: '39.95',
                        portugal: '1.95',
                    },
                    price: {
                        'en': '1.95',
                        'pt': '9.95',
                        'de': '1.95',
                        'es': '1.95',
                        'fr': '1.95',
                        'dk': '13.95',
                        'it': '1.95',
                        'hu': '699',
                        'mx': '39.95',
                        'portugal': '1.95',
                    },
                    paypal_plan_id: {
                        'en': 'P-6WY009976M8826137MVUYJUI',
                        'pt': 'P-4LC32466K3622815RMZLM63I',
                        'de': 'P-8HX76460Y7475523BM25RKMQ',
                        'es': 'P-8HX76460Y7475523BM25RKMQ',
                        'fr': 'P-8HX76460Y7475523BM25RKMQ',
                        'dk': 'P-0GD30837VA912800TM3L73QQ',
                        'it': 'P-8HX76460Y7475523BM25RKMQ',
                        'hu': 'P-71N87803YN419212PM3L744I',
                        'mx': 'P-52N20447AP089524WM4JCBTY',
                        'portugal': 'P-8HX76460Y7475523BM25RKMQ',
                    },
                    options: [
                        {
                            title: t("unlimitedStaticQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeQRCodeDesigns"),
                            available: true,
                        },
                        {
                            title: t("unlimitedDynamicQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeLandingPageDesigns"),
                            available: true,
                        },
                        {
                            title: t("scansAnalytics"),
                            available: true,
                        },
                        {
                            title: t("allDownloadFormatOptions"),
                            available: true,
                        },
                    ]
                },
                {
                    name: t("unlimitedPlan"),
                    nameEnglish: "Unlimited Plan",
                    shortName: 'Unlimited',
                    price_per_period: t("slashMonth"),
                    best: false,
                    price_id: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
                    note: t("renewsEveryMonthYouMayCancelAnytime"),
                    isAfterTrial: true,
                    yearlyPrice: {
                        en: '28.95',
                        pt: '149.95',
                        de: '28.95',
                        es: '28.95',
                        fr: '28.95',
                        dk: '199.95',
                        it: '28.95',
                        hu: '10699',
                        mx: '595.95',
                        portugal: '28.95',
                    },
                    price: {
                        'en': '28.95',
                        'pt': '149.95',
                        'de': '28.95',
                        'es': '28.95',
                        'fr': '28.95',
                        'dk': '199.95',
                        'it': '28.95',
                        'hu': '10699',
                        'mx': '595.95',
                        'portugal': '28.95',
                    },
                    paypal_plan_id: {
                        'en': 'P-2PD155281Y245623PMZLNFZA',
                        'pt': 'P-0LA79173GA657594VMZLNGJQ',
                        'de': 'P-0JK17181J6235622LM25RHXI',
                        'es': 'P-0JK17181J6235622LM25RHXI',
                        'fr': 'P-0JK17181J6235622LM25RHXI',
                        'dk': 'P-0874093121496891GM3MAAUQ',
                        'it': 'P-0JK17181J6235622LM25RHXI',
                        'hu': 'P-5JK96774LV3922111M3MABLQ',
                        'mx': 'P-9G096934B35255449M4JBYAI',
                        'portugal': 'P-0JK17181J6235622LM25RHXI',
                    },
                    options: [
                        {
                            title: t("unlimitedStaticQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeQRCodeDesigns"),
                            available: true,
                        },
                        {
                            title: t("unlimitedDynamicQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeLandingPageDesigns"),
                            available: true,
                        },
                        {
                            title: t("scansAnalytics"),
                            available: true,
                        },
                        {
                            title: t("allDownloadFormatOptions"),
                            available: true,
                        },
                    ]
                },
                {
                    name: t("yearlyPlan"),
                    nameEnglish: "Yearly Plan",
                    shortName: 'Yearly',
                    price_per_period: t("slashMonth"),
                    best: false,
                    price_id: 'price_1QBBKeG2gC76wG4CwQuUHux0',
                    note: t("renewsEveryYearYouMayCancelAnytime"),
                    isAfterTrial: 'both',
                    yearlyPrice: {
                        en: '179.40',
                        pt: '925.95',
                        de: '179.40',
                        es: '179.40',
                        fr: '179.40',
                        dk: '1199.95',
                        it: '179.40',
                        hu: '65599',
                        mx: '3495.95',
                        portugal: '179.40',
                    },
                    price: {
                        'en': '14.95',
                        'pt': '77.10',
                        'de': '14.95',
                        'es': '14.95',
                        'fr': '14.95',
                        'dk': '99.95',
                        'it': '14.95',
                        'hu': '5499',
                        'mx': '291',
                        'portugal': '14.95',
                    },
                    paypal_plan_id: {
                        'en': 'P-0CF06977830134921MVUYKKA',
                        'pt': 'P-8SU09002P7245240LMZLM27I',
                        'de': 'P-10068147TW535905MM25RLGI',
                        'es': 'P-10068147TW535905MM25RLGI',
                        'fr': 'P-10068147TW535905MM25RLGI',
                        'dk': 'P-0XY325601C5545827M3L7YWQ',
                        'it': 'P-10068147TW535905MM25RLGI',
                        'hu': 'P-8SV64754VR749811MM3L7X3A',
                        'mx': 'P-0WV84734TP085551YM4JB6OA',
                        'portugal': 'P-10068147TW535905MM25RLGI',
                    },
                    options: [
                        {
                            title: t("unlimitedStaticQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeQRCodeDesigns"),
                            available: true,
                        },
                        {
                            title: t("unlimitedDynamicQRCodes"),
                            available: true,
                        },
                        {
                            title: t("customizeLandingPageDesigns"),
                            available: true,
                        },
                        {
                            title: t("scansAnalytics"),
                            available: true,
                        },
                        {
                            title: t("allDownloadFormatOptions"),
                            available: true,
                        },
                    ]
                },
            ]
        }
    }, [t, subscription])

    return { accountNewPlans };
}

export default useAccountNewPlans