import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { BiLeftArrowAlt } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useEditQrCodeContext } from '../../context/EditQrCodeContext'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import { trialPeriodEndDate } from '../../helpers/functions'
import { useViewport } from '../../hooks/useViewport'
import { useGetCodeByIdQuery } from '../../api/api'

import DashboardEditContent from '../../components/DashboardEditContent/DashboardEditContent'
import EditCodePreviewBlock from '../../components/EditCodePreviewBlock/EditCodePreviewBlock'
import EditCodeLandingPage from '../../components/EditCodeLandingPage/EditCodeLandingPage'
import DashboardNavigation from '../../components/DashboardNavigation/DashboardNavigation'
import TrialPeriodBlock from '../../components/TrialPeriodBlock/TrialPeriodBlock'
import EditCodeDesign from '../../components/EditCodeDesign/EditCodeDesign'

import editDesignSvg from '../../assets/icons/edit-design.svg'

const EditCode = () => {
    const subscription = useSelector((state) => state.app.subscription)
    const [showAlert, setShowAlert] = useState(true)
    const [editDesign, setEditDesign] = useState('')
    const [trialDaysLeft, setTrialDaysLeft] = useState(7)

    const triggerValidationRef = useRef()

    const { id } = useParams()
    const { t } = useTranslation()
    const { isMobile } = useViewport()

    const navigate = useCustomNavigate()

    const {
        isGenerating, setEditCodeData, handleSaveEditCode,
        codeName, setCodeName, handleDiscardCreation, resetDesignStyles,
        editMobileStep, setEditMobileStep, type
    } = useEditQrCodeContext()

    const { data: codeData } = useGetCodeByIdQuery(id, { skip: !id })

    const triggerValidation = useCallback((trigger) => {
        if (trigger) {
            triggerValidationRef.current = trigger
        }
    }, [])

    const handleDiscardEdit = () => {
        navigate('/dashboard')
    }

    const handleTriggerAndSave = async () => {
        if (triggerValidationRef.current) {
            const result = await triggerValidationRef.current()

            if (result) {
                handleSaveEditCode()
            }
        }
    }

    const handleToPreviousStep = () => {
        setEditMobileStep((prev) => {
            return prev - 1
        })
    }

    const handleNextStep = () => {
        setEditMobileStep((prev) => {
            return prev + 1
        })
        window.scrollTo(0, 0)
    }

    const handleCancelCreation = () => {
        navigate('/dashboard')
    }

    const handleTriggerAndContinue = async () => {
        if (triggerValidationRef.current) {
            const result = await triggerValidationRef.current()

            if (result) {
                handleNextStep()
            }
        }
    }

    useEffect(() => {
        return () => {
            handleDiscardCreation()
        }
    }, [])

    useEffect(() => {
        if (subscription && subscription.plan === 'Free') {
            const result = trialPeriodEndDate(subscription.trial_end)

            setTrialDaysLeft(result)
        }
    }, [subscription])

    useEffect(() => {
        if (codeData) {
            setCodeName(codeData.name)
            setEditCodeData(codeData)
        }
    }, [codeData, setEditCodeData, setCodeName])

    return (
        <div className='user-dashboard-wrapper-edit'>
            {isMobile ? (
                <div className='mobile-creation-flow-wrapper'>
                    {editMobileStep === 1 && (
                        <div className='mobile-creation-step-wrapper'>
                            <h5 className='mobile-creation-heading'>{t("editQRCode")}</h5>
                            <div className='content-creation-input-wrapper'>
                                <label className='content-creation-input-label'>{t("nameQrCodeWithoutDots")}</label>
                                <input
                                    type='text'
                                    className='form-input-dashboard'
                                    placeholder={t("nameQrCode")}
                                    value={codeName}
                                    onChange={(e) => setCodeName(e.target.value)}
                                />
                            </div>
                        </div>
                    )}
                    {
                        editMobileStep === 2 && (
                            <div className='mobile-creation-step-wrapper mb-20'>
                                <div className='mobile-creation-steps-header'>
                                    <div onClick={handleToPreviousStep} className='mobile-creation-header-back'>
                                        <MdKeyboardArrowLeft size={28} fill="#9747FF" />
                                        <span className='mobile-creation-header-back-word'>
                                            {t("back")}
                                        </span>
                                    </div>
                                </div>
                                <DashboardEditContent type={codeData?.data?.kind} triggerValidation={triggerValidation} />
                            </div>
                        )
                    }
                    {
                        editMobileStep === 3 && (
                            <div className='mobile-creation-step-wrapper mb-20'>
                                <div className='mobile-creation-steps-header'>
                                    <div onClick={handleToPreviousStep} className='mobile-creation-header-back'>
                                        <MdKeyboardArrowLeft size={28} fill="#9747FF" />
                                        <span className='mobile-creation-header-back-word'>
                                            {t("back")}
                                        </span>
                                    </div>
                                </div>
                                <h5 className='mobile-creation-heading'>{t("customizeQRCode")}</h5>
                                <div className=''>
                                    <EditCodeDesign mobile={true} />
                                </div>
                            </div>
                        )
                    }
                    {
                        editMobileStep === 4 && type !== 'website_url' && (
                            <div className='mobile-creation-step-wrapper mb-20'>
                                <div className='mobile-creation-steps-header'>
                                    <div onClick={handleToPreviousStep} className='mobile-creation-header-back'>
                                        <MdKeyboardArrowLeft size={28} fill="#9747FF" />
                                        <span className='mobile-creation-header-back-word'>
                                            {t("back")}
                                        </span>
                                    </div>
                                </div>
                                <h5 className='mobile-creation-heading'>{t("customizeLandingPage")}</h5>
                                <div className=''>
                                    <EditCodeLandingPage mobile={true} />
                                </div>
                            </div>
                        )
                    }
                    {
                        (editMobileStep === 5 || (editMobileStep === 4 && type === 'website_url')) && (
                            <div className='mobile-creation-step-wrapper mb-20'>
                                <div className='mobile-creation-steps-header'>
                                    <div onClick={handleToPreviousStep} className='mobile-creation-header-back'>
                                        <MdKeyboardArrowLeft size={28} fill="#9747FF" />
                                        <span className='mobile-creation-header-back-word'>
                                            {t("back")}
                                        </span>
                                    </div>
                                </div>
                                <div className='mobile-creation-preview-block'>
                                    <span className='dashboard-content-creation-preview-title'>{t("preview")}</span>
                                    <EditCodePreviewBlock />
                                </div>
                            </div>
                        )
                    }
                    <div className='mobile-creation-flow-actions-block'>
                        {(editMobileStep === 1 || editMobileStep === 3) && (
                            <button onClick={handleCancelCreation} className='mobile-creation-flow-cancel-btn'>{t("discard")}</button>
                        )}
                        {editMobileStep === 2 && (
                            <button onClick={handleTriggerAndContinue} className='mobile-creation-flow-next-btn'>{t("next")}</button>
                        )}
                        {(editMobileStep === 1 || editMobileStep === 3 || (editMobileStep === 4 && type !== 'website_url')) && (
                            <button onClick={handleNextStep} className='mobile-creation-flow-next-btn'>{t("next")}</button>
                        )}
                        {(editMobileStep === 5 || (editMobileStep === 4 && type === 'website_url')) && (
                            <button
                                disabled={!isGenerating ? false : true}
                                onClick={handleSaveEditCode}
                                className='mobile-creation-flow-next-btn relative'
                            >
                                {isGenerating && (
                                    <svg style={{ position: 'absolute', left: 'calc(50% - 55px)' }} className='stripe-loader' width={25} height={25} fill="#9747FF" viewBox="25 25 50 50">
                                        <circle r="20" cy="50" cx="50"></circle>
                                    </svg>
                                )}
                                {t("save")}
                            </button>
                        )}
                    </div>
                </div >
            ) : (
                <div style={{ backgroundColor: '#1B003E', width: '100%', display: 'flex' }}>
                    <DashboardNavigation setOverviewCode={() => null} />
                    <div className='user-dashboard-content user-dashboard-content-new'>
                        <div className='dashboard-content-container-wrapper'>
                            <div className='dashboard-content-container-left-side'>
                                <div className='dashboard-content-create-block-wrapper'>
                                    <h2 className='dashboard-content-create-new-title'>
                                        {t("editQRCode")}
                                    </h2>
                                    <div className='dashboard-content-create-block'>
                                        <div className='dashboard-content-create-input-block'>
                                            <input value={codeName} onChange={(e) => setCodeName(e.target.value)} placeholder={t("nameQrCode")} className='content-create-name-input' type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ minHeight: showAlert && subscription?.plan === 'Free' ? 'calc(100vh - 328px)' : 'calc(100vh - 255px)' }} className='dashboard-create-container'>
                                    <DashboardEditContent type={codeData?.data?.kind} triggerValidation={triggerValidation} codeData={codeData} />
                                </div>
                            </div>
                            {editDesign ? (
                                <div style={{ minHeight: showAlert && subscription?.plan === 'Free' ? 'calc(100vh - 328px)' : 'calc(100vh - 255px)' }} className='dashboard-content-container-right-side justify-start px-4'>
                                    <div className='edit-design-header'>
                                        <div onClick={() => setEditDesign(false)} className='edit-design-acbk-block cursor-pointer'>
                                            <BiLeftArrowAlt size={32} fill="#334155" />
                                            <span className='edit-design-back-text'>{t("editDesign")}</span>
                                        </div>
                                        <span onClick={resetDesignStyles} className='edit-design-clear-btn'>{t("reset")}</span>
                                    </div>
                                    <EditCodeDesign mobile={false} />
                                    {codeData?.data?.kind !== 'website_url' && (
                                        <EditCodeLandingPage mobile={false} />
                                    )}
                                </div>
                            ) : (
                                <div style={{ minHeight: showAlert && subscription?.plan === 'Free' ? 'calc(100vh - 270px)' : 'calc(100vh - 195px)' }} className='dashboard-content-container-right-side'>
                                    <span className='dashboard-content-creation-preview-title'>{t("preview")}</span>
                                    <EditCodePreviewBlock />
                                    <button onClick={() => setEditDesign(true)} className='dashboard-content-edit-preview-button'>
                                        <img src={editDesignSvg} alt="" />
                                        {t("editDesign")}
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className='dashboard-content-creation-actions'>
                            <button onClick={handleDiscardEdit} className='content-creation-actions-cancel'>{t("discard")}</button>
                            <button
                                onClick={handleTriggerAndSave}
                                disabled={(!isGenerating) ? false : true}
                                className='content-creation-actions-generate relative'
                            >
                                {isGenerating && (
                                    <svg style={{ position: 'absolute', left: 'calc(50% - 65px)' }} className='stripe-loader relative' width={25} height={25} fill="#9747FF" viewBox="25 25 50 50">
                                        <circle r="20" cy="50" cx="50"></circle>
                                    </svg>
                                )}
                                {t("save")}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default EditCode